import axios from 'axios'
import { getToken } from '@/utils/token.js'

// run : 贯能德育管理系统
const wxAppID = "wx310feff3845d3ef3"; // run
const httpUrl = "https://www.zjxsgl.com/"; // run
// const httpUrl = "http://localhost:1000"; // local
const siteRecordNum = "杭州贯能教育科技有限公司 © 2019-2022 浙ICP备2021014741号-1"; // run

// test : 贯能德育管理系统PLUS
// const wxAppID  = "wxee94a6a2f82133e3"; // test
// const httpUrl  = "https://www.xsdygl.com"; // test
// const siteRecordNum  = "杭州贯能教育科技有限公司 © 2019-2022 浙ICP备2021014741号-2"; // test

// const httpUrl  = "http://localhost:1000"; // local

const service = axios.create({
  baseURL: httpUrl,
  timeout: 30000,
})
export function getWxAppID() {
  return wxAppID;
}
export function getHttpUrl() {
  return httpUrl;
}
export function getSiteRecordNum() {
  return siteRecordNum;
}

service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers.Authorization = getToken();
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    return res
  },
  (error) => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)
export function get(url, params) {
  return service.get(url, { params })
}
export function post(url, data) {
  return service.post(url, data)
}
export function put(url, data) {
  return service.put(url, data)
}
export function del(url) {
  return service.delete(url)
}
export default service
